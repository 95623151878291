/* Style for the notifications stack container */
.arc-TimedToastNotifications-stack {
    display: flex;
    flex-direction: column-reverse;
    position: fixed;
    z-index: 10000;
}

/* Base styles for the notification */
.arc-TimedToastNotifications {
    min-height: 64px;
    width: 440px;
    background: var(--white);
    display: flex;
    box-shadow: 0 16px 24px 0 rgba(21, 41, 53, 0.24);
    opacity: 1;
    transition: left, opacity 0.4s, 0.3s ease;
    transform: translateY(0);
    z-index: 10000;
    margin-bottom: 10px;
}

/* Styles for react-transition-group states */
.arc-TimedToastNotifications-appear {
    position: relative;
    left: 460px;
}
.arc-TimedToastNotifications-appear-active {
    position: relative;
    left: 0;
    transition: left 0.4s ease;
}
.arc-TimedToastNotifications-exit {
    position: relative;
    left: 0;
}
.arc-TimedToastNotifications-exit-active {
    position: relative;
    left: 460px;
    transition: left 0.4s ease;
}
